function Free() {

  return (
    <div className="free">
      <div className="up">
        <p>Start leveling up your JavaScript skills. It's free</p>
      </div>
      <div className="it-is-free">
        <p>JS-Pro was created just to help junior developers and newbies </p>
      </div>
    </div>
  );
}

export default Free;
