function Offer() {
  return (
    <div className="offer">
      <p className="offer-head">We have resources on</p>
      <div className="resoources-we-offer">
        <p>JavaScript for Front End</p>
        <p>JavaScript for Back End</p>
        <p>Building your Projects</p>
        <p>Building your Resume</p>
        <p>Building yor LinkedIn Profile</p>
        <p>Getting Jobs</p>
        <p>Preparing for Interviews</p>
        <p>JavaScript Quiz</p>

      </div>
    </div>
  );
}

export default Offer;
